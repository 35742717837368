<template>
    <div id="app">
        <span>Hallo Sarah</span><span style="color: red; margin-left: 0.2rem;">&#10084;</span>
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
